import { useQuery } from 'react-query'

import api from '../../../services/api'

const useGetobtendoTrilha = (payload) => {
  const idDepartamento = localStorage.getItem('idDepartamento')
  return useQuery(
    ['chat_useGetobtendoTrilha', { payload }],
    async () => {
      const response = await api.get(`/v1/api/public/obtendoTrilha/${idDepartamento}`, {
        params: payload
      })
      return response.data
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 2500,
      refetchInterval: 2500
    }
  )
}

export { useGetobtendoTrilha }
