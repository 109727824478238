/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
// @ts-nocheck

import React, { useState } from 'react'
import { BsFillPersonXFill } from 'react-icons/bs'
import { useMutation } from 'react-query'

import {
  Icon, Input, Spinner, FormControl, Flex, Box,
  FormLabel,
  Heading, Button, FormErrorMessage, Divider,
  Select
} from '@chakra-ui/react'
import { useFormik, FormikProvider } from 'formik'

import handleButtonClick from '../../../../helpers/loadingButton/loadingButton'
import api from '../../../../services/api'
import formCreateFormsWeb from './schemaForm'
import ButtonNavigate from '../ButtonNavigate'

const AddCacelamentoConvenio = ({ nome, codpage, tipoSolicitacao }) => {
  const [isLoadingButton, setIsLoadingButton] = useState(false)

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      tipoSolicitacao,
      codpage,
      payload: {
        nome: '',
        cnpj_cpf: '',
        beneficiario: '',
        observacoes: ''
      }
    },
    validationSchema: formCreateFormsWeb,
    onSubmit: (payload) => {
      const obj = {
        tipoSolicitacao: payload.tipoSolicitacao,
        codpage: payload.codpage,
        payload: {
          nome: payload.nome,
          cnpj_cpf: payload.cnpj_cpf,
          beneficiario: payload.beneficiario,
          observacoes: payload.observacoes
        }
      }
      handleAttCertificate.mutate(obj)
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  })

  const { values, errors, touched, handleSubmit, resetForm, handleChange } = formik

  const handleAttCertificate = useMutation(async (values) => {
    const response = await api.post('/v1/api/public/solicitacoes-chatbot', values)
    return response.data
  }, {
    onSuccess: (data) => {
      resetForm()
    }
  })

  const canSubmit = () => {
    return (
      values.nome !== '' &&
      values.cnpj_cpf !== '' &&
      values.beneficiario !== '' &&
      values.observacoes !== ''
    )
  }

  const formatCpfCnpj = (value) => {
    console.log('Input:', value)
    const numericValue = value.replace(/\D/g, '')

    if (numericValue.length <= 11) {
      const formattedCpf = numericValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      console.log('Formatted CPF:', formattedCpf)
      return formattedCpf
    } else {
      const formattedCnpj = numericValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      console.log('Formatted CNPJ:', formattedCnpj)
      return formattedCnpj
    }
  }

  return (
    <>
      <ButtonNavigate />
      <Flex pos={'fixed'} zIndex={9999} top={0} flexDir={'column'} align={'center'} bg={'white'} justify='center' h='100vh' m={0} fontSize='md'>
        <Box bg='white' p={8} w={'100vw'} rounded='md' mt={-8}>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Flex flexDir={'column'}>
              <Heading fontSize={20}>Cancelamento de {nome}</Heading>
            </Flex>
            <Icon boxShadow={'md'} mb={4} borderRadius={'12px 0 12px 12px'} p={2} w={'50px'} h={'50px'} bg={'red.500'} color={'white'} fontSize={24} as={BsFillPersonXFill} />
          </Flex>
          <Divider />
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <FormControl isInvalid={errors.cnpj_cpf && touched.cnpj_cpf}>
                <FormLabel mt={2}>CNPJ ou CPF</FormLabel>
                <Input
                  maxLength={18}
                  placeholder='cnpj ou cpf'
                  autoComplete={false}
                  id='cnpj_cpf'
                  name='cnpj_cpf'
                  type='text'
                  variant='outline'
                  onChange={(e) => {
                    const formattedValue = formatCpfCnpj(e.target.value)
                    handleChange({ target: { name: 'cnpj_cpf', value: formattedValue } })
                  }}
                  value={values.cnpj_cpf}
                />
                {touched.cnpj_cpf && errors.cnpj_cpf && (
                  <FormErrorMessage>
                    {errors.cnpj_cpf}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={errors.nome && touched.nome}>
                <FormLabel mt={4}>Nome</FormLabel>
                <Input
                  placeholder='nome'
                  autoComplete={false}
                  id='nome'
                  name='nome'
                  type='text'
                  variant='outline'
                  onChange={handleChange}
                  value={values.nome}
                />
                {(touched.nome === true) && errors.nome && (
                  <FormErrorMessage>
                    {errors.nome}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={errors.beneficiario && touched.beneficiario}>
                <FormLabel mt={2}>Beneficiario</FormLabel>
                <Select
                  maxLength={14}
                  autoComplete={false}
                  id='beneficiario'
                  name='beneficiario'
                  type='checkbox'
                  variant='outline'
                  onChange={handleChange}
                  value={values.beneficiario}
                >
                  <option value="Titular">Titular</option>
                  <option value="Dependente">Dependente</option>
                </Select>
                {touched.beneficiario && errors.beneficiario && (
                  <FormErrorMessage>
                    {errors.beneficiario}
                  </FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={errors.observacoes && touched.observacoes}>
                <FormLabel mt={4}>Observações</FormLabel>
                <Input
                  placeholder='Observações'
                  autoComplete={false}
                  id='observacoes'
                  name='observacoes'
                  type='text'
                  variant='outline'
                  onChange={handleChange}
                  value={values.observacoes}
                />
                {(touched.observacoes === true) && errors.observacoes && (
                  <FormErrorMessage>
                    {errors.observacoes}
                  </FormErrorMessage>
                )}
              </FormControl>

              <Button
                _hover={{
                  bg: '#229544',
                  color: 'white',
                  boxShadow: 'md'
                }}
                mt={4}
                bg={'green'}
                color={'white'}
                textTransform={'uppercase'}
                type='submit'
                width='full'
                variant='solid'
                onClick={() => {
                  handleButtonClick(setIsLoadingButton)
                }}
                isDisabled={!canSubmit}
              >
                {isLoadingButton ? 'Solicitando Atualização...' : 'Solicitar Atualização'}
                {isLoadingButton && <Spinner ml={2} />}
              </Button>
            </form>
          </FormikProvider>
        </Box>

      </Flex>
    </>
  )
}

export default AddCacelamentoConvenio
