/* eslint-disable react/prop-types */
import React from 'react'

import {
  Alert,
  AlertIcon,
  Box,
  Text
} from '@chakra-ui/react'

export default function InformacaoResposta ({ resposta, pergunta }) {
  const linhasResposta = resposta.map((res, index) => (
    <Box mt={8} key={index}>
      <Text borderBottom={'1px solid #229544'} whiteSpace='pre-wrap'> {res.replaceAll('\n', '\n')}</Text>
    </Box>
  ))
  return (
    <Box p={4} h={'90vh'} paddingBottom={20} overflowY={'scroll'} mt={10}>
      <Text ml={2} fontWeight={'bold'} w={'100%'}>{pergunta}</Text>
      <Text mb={8} borderBottom={'1px solid #229544'} ml={2} mt={-2}>Resultados ({resposta.length})</Text>

      {linhasResposta}

      <Alert pos={'fixed'} bottom={0} left={0} status='info'>
        <AlertIcon />
        Essa informação do chat tem duração de 24hrs
      </Alert>
    </Box>
  )
}
