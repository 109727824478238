/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
// @ts-nocheck

import React, { useState } from 'react'
import { BsFillPersonXFill } from 'react-icons/bs'
import { useMutation } from 'react-query'

import {
  Icon, Input, Spinner, FormControl, Flex, Box,
  FormLabel,
  Heading, Button, FormErrorMessage, Divider
} from '@chakra-ui/react'
import { useFormik, FormikProvider } from 'formik'

import handleButtonClick from '../../../../helpers/loadingButton/loadingButton'
import api from '../../../../services/api'
import formCreateFormsWeb from './schemaForm'
import ButtonNavigate from '../ButtonNavigate'

const AddAtualizacaoCertficado = ({ nome, codpage, tipoSolicitacao }) => {
  const [isLoadingButton, setIsLoadingButton] = useState(false)

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      tipoSolicitacao,
      codpage,
      payload: {
        cnpj_cpf: '',
        aneps: '',
        data_aneps: '',
        certificacao_lgpd: '',
        data_lgpd: '',
        certificacao_lavagem: '',
        data_certificacao_lavagem: ''
      }
    },
    validationSchema: formCreateFormsWeb,
    onSubmit: (payload) => {
      const obj = {
        tipoSolicitacao: payload.tipoSolicitacao,
        codpage: payload.codpage,
        payload: {
          cnpj_cpf: payload.cnpj_cpf,
          aneps: payload.aneps,
          data_aneps: payload.data_aneps,
          certificacao_lgpd: payload.certificacao_lgpd,
          data_lgpd: payload.data_lgpd,
          certificacao_lavagem: payload.certificacao_lavagem,
          data_certificacao_lavagem: payload.data_certificacao_lavagem
        }
      }
      handleAttCertificate.mutate(obj)
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  })

  const { values, errors, touched, handleSubmit, resetForm, handleChange } = formik

  const handleAttCertificate = useMutation(async (values) => {
    const response = await api.post('/v1/api/public/solicitacoes-chatbot', values)
    return response.data
  }, {
    onSuccess: (data) => {
      resetForm()
    }
  })

  const canSubmit = () => {
    return (
      values.cnpj_cpf !== '' &&
      values.aneps !== '' &&
      values.data_aneps !== '' &&
      values.certificacao_lgpd !== '' &&
      values.data_lgpd !== '' &&
      values.certificacao_lavagem !== '' &&
      values.data_certificacao_lavagem !== ''
    )
  }

  const formatCpfCnpj = (value) => {
    console.log('Input:', value)
    const numericValue = value.replace(/\D/g, '')

    if (numericValue.length <= 11) {
      const formattedCpf = numericValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      console.log('Formatted CPF:', formattedCpf)
      return formattedCpf
    } else {
      const formattedCnpj = numericValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      console.log('Formatted CNPJ:', formattedCnpj)
      return formattedCnpj
    }
  }

  return (
    <>
      <ButtonNavigate />
      <Flex pos={'fixed'} zIndex={9999} top={0} flexDir={'column'} align={'center'} bg={'white'} justify='center' h='100vh' m={0} fontSize='md'>
        <Box bg='white' p={8} w={'100vw'} rounded='md' mt={-8}>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Flex flexDir={'column'}>
              <Heading fontSize={20}>Atualização de Certificados</Heading>
              <Heading mb={4} color={'gray.600'} fontSize={16}>{nome}</Heading>
            </Flex>
            <Icon boxShadow={'md'} mb={4} borderRadius={'12px 0 12px 12px'} p={2} w={'50px'} h={'50px'} bg={'red.500'} color={'white'} fontSize={24} as={BsFillPersonXFill} />
          </Flex>
          <Divider />
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit}>
              <FormControl isInvalid={errors.cnpj_cpf && touched.cnpj_cpf}>
                <FormLabel mt={2}>CNPJ ou CPF</FormLabel>
                <Input
                  maxLength={18}
                  placeholder='cnpj ou cpf'
                  autoComplete={false}
                  id='cnpj_cpf'
                  name='cnpj_cpf'
                  type='text'
                  variant='outline'
                  onChange={(e) => {
                    const formattedValue = formatCpfCnpj(e.target.value)
                    handleChange({ target: { name: 'cnpj_cpf', value: formattedValue } })
                  }}
                  value={values.cnpj_cpf}
                />
                {touched.cnpj_cpf && errors.cnpj_cpf && (
                  <FormErrorMessage>
                    {errors.cnpj_cpf}
                  </FormErrorMessage>
                )}
              </FormControl>

              <Flex gap={2}>
                <FormControl w={'70%'} isInvalid={errors.aneps && touched.aneps}>
                  <FormLabel mt={4}>Aneps</FormLabel>
                  <Input
                    placeholder='aneps'
                    autoComplete={false}
                    id='aneps'
                    name='aneps'
                    type='text'
                    variant='outline'
                    onChange={handleChange}
                    value={values.aneps}
                  />
                  {(touched.aneps === true) && errors.aneps && (
                    <FormErrorMessage>
                      {errors.aneps}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={9} w={'30%'} isInvalid={errors.data_aneps && touched.data_aneps}>
                  <FormLabel ></FormLabel>
                  <Input
                    maxLength={14}
                    autoComplete={false}
                    id='data_aneps'
                    name='data_aneps'
                    type='date'
                    variant='outline'
                    onChange={handleChange}
                    value={values.data_aneps}
                  />
                  {touched.data_aneps && errors.data_aneps && (
                    <FormErrorMessage>
                      {errors.data_aneps}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Flex>

              <Flex gap={2}>
                <FormControl w={'70%'} isInvalid={errors.certificacao_lgpd && touched.certificacao_lgpd}>
                  <FormLabel mt={4}>Lgpd</FormLabel>
                  <Input
                    placeholder='Lgpd'
                    autoComplete={false}
                    id='certificacao_lgpd'
                    name='certificacao_lgpd'
                    type='text'
                    variant='outline'
                    onChange={handleChange}
                    value={values.certificacao_lgpd}
                  />
                  {(touched.certificacao_lgpd === true) && errors.certificacao_lgpd && (
                    <FormErrorMessage>
                      {errors.certificacao_lgpd}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={9} w={'30%'} isInvalid={errors.data_lgpd && touched.data_lgpd}>
                  <FormLabel ></FormLabel>
                  <Input
                    maxLength={14}
                    autoComplete={false}
                    id='data_lgpd'
                    name='data_lgpd'
                    type='date'
                    variant='outline'
                    onChange={handleChange}
                    value={values.data_lgpd}
                  />
                  {touched.data_lgpd && errors.data_lgpd && (
                    <FormErrorMessage>
                      {errors.data_lgpd}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Flex>

              <Flex gap={2}>
                <FormControl w={'70%'} isInvalid={errors.certificacao_lavagem && touched.certificacao_lavagem}>
                  <FormLabel mt={4}>Lavagem</FormLabel>
                  <Input
                    placeholder='Lavagem'
                    autoComplete={false}
                    id='certificacao_lavagem'
                    name='certificacao_lavagem'
                    type='text'
                    variant='outline'
                    onChange={handleChange}
                    value={values.certificacao_lavagem}
                  />
                  {(touched.certificacao_lavagem === true) && errors.certificacao_lavagem && (
                    <FormErrorMessage>
                      {errors.certificacao_lavagem}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mt={9} w={'30%'} isInvalid={errors.data_certificacao_lavagem && touched.data_certificacao_lavagem}>
                  <FormLabel ></FormLabel>
                  <Input
                    maxLength={14}
                    autoComplete={false}
                    id='data_certificacao_lavagem'
                    name='data_certificacao_lavagem'
                    type='date'
                    variant='outline'
                    onChange={handleChange}
                    value={values.data_certificacao_lavagem}
                  />
                  {touched.data_certificacao_lavagem && errors.data_certificacao_lavagem && (
                    <FormErrorMessage>
                      {errors.data_certificacao_lavagem}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Flex>

              <Button
                _hover={{
                  bg: '#229544',
                  color: 'white',
                  boxShadow: 'md'
                }}
                mt={4}
                bg={'green'}
                color={'white'}
                textTransform={'uppercase'}
                type='submit'
                width='full'
                variant='solid'
                onClick={() => {
                  handleButtonClick(setIsLoadingButton)
                }}
                isDisabled={!canSubmit}
              >
                {isLoadingButton ? 'Solicitando Atualização...' : 'Solicitar Atualização'}
                {isLoadingButton && <Spinner ml={2} />}
              </Button>
            </form>
          </FormikProvider>
        </Box>

      </Flex >
    </>
  )
}

export default AddAtualizacaoCertficado
