/* eslint-disable react/prop-types */
import React from 'react'

import { Stack, Box, Alert, AlertIcon } from '@chakra-ui/react'

import InformacaoResposta from './components/InformacaoResposta'
import Loading from './components/Loading'
import { useGetPesquisas } from './hooks/useGetPesquisas'
import ButtonNavigate from '../paginaFormularios/components/ButtonNavigate'
export default function PaginaResposta ({ codePage }) {
  const { isLoading: loadingPesquisa, data: pesquisa, isError } = useGetPesquisas({
    codePage
  })
  const listaPesquisa = pesquisa || []
  return (
    <div className='App'>
      <ButtonNavigate />
      <Stack w={'100%'} h={'100vh'} bg={'white'} zIndex={9999} pos={'absolute'} top={0} left={0}>
        <Box w='100%'>
          {loadingPesquisa
            ? (
            <Loading/>
              )
            : (
            <>
              {isError
                ? (
                <Alert status='error'>
                  <AlertIcon />
                  Desculpe!Ocorreu algum erro, tente novamente, mais tarde!
                </Alert>
                  )
                : (
                <InformacaoResposta resposta={listaPesquisa.resposta} pergunta={listaPesquisa.pergunta}/>
                  )}
            </>
              )}
        </Box>
      </Stack>
    </div>
  )
}
